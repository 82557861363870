import React, { useEffect, useState } from 'react'
import { Box, Button, Card, Grid, } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { getFilteredNewsData } from '../../../Services/UserServices';
import parse from 'html-react-parser';
import Loader from '../../../Components/Loader/Loader';
import { saveToLocalStorage, scrollToLastVisitedItem } from '../../../Helper/helper';

const Img = styled('img')({
    margin: 'auto 0px auto 0px',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
});

const Item = styled(Card)(({ theme }) => ({
    backgroundColor: "#fff",
    boxShadow: 'none',
    marginBottom: 20,
    marginTop: 20
}));

const boxStyle2 = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexWrap: 'wrap',
    paddingRight: "10px",
    paddingLeft: "10px",
    paddingBottom: "10px"
}

const boxStyleSecondWithBorder = {
    display: 'flex',
    flexDirection: 'row',
    // flexWrap: 'wrap',
    gap: "10px",
    borderBottom: '1px solid #e5e5e5',
    marginTop: '15px',
    paddingBottom: '15px'
}

function CategoryNewsList() {

    const navigate = useNavigate();

    const { state } = useLocation();
    const { category, type } = state;

    const [filteredNews, setFilteredNews] = useState([]);

    // Loader
    const [loading, setLoading] = useState(false);

    const getData = async () => {
        setLoading(true);
        window.scrollTo(0, 0)
        let filter = {
            "categoryName": encodeURIComponent(category),
            "type": type,
            "count": null
        }
        await getFilteredNewsData(filter).then((res) => {
            let data = res?.data?.latestNews;
            setFilteredNews(data);
            setLoading(false);
            scrollToLastVisitedItem();
        }).catch((err) => { setLoading(false); scrollToLastVisitedItem(); console.log("Error:", err) })
    }

    useEffect(() => {
        getData();
    }, [category, type]);

    const navigateNewsDetails = (id) => {
        saveToLocalStorage(window.location.pathname, window.scrollY);
        navigate(`/user/news/details/${id}`);
    }

    const loadNews = (latestNews) => {
        return (latestNews?.map((latest, index) => {
            const renderFilteredContent = (htmlContent) => {
                let arr = [];
                parse(htmlContent, {
                    replace: (domNode) => {
                        arr.push(domNode);
                        return null;
                    },
                });

                const imageNode = arr.find((node) => node?.name === 'img');
                const iframeNode = arr.find((node) => node?.name === 'iframe');
                const descriptionNode = arr.find((node) => node?.type === "text");

                const image = imageNode ? (
                    <Box
                        component="img"
                        alt="News"
                        src={imageNode?.attribs?.src || "/DefaultImages/news_default_img.jpeg"}
                        sx={{
                            marginTop: '10px',
                            borderRadius: '5px',
                            width: {
                                xs: '85px', // Smaller height on extra-small screens (mobile)
                                sm: '150px', // Medium height on small screens (tablet)
                                md: '150px', // Larger height on medium screens (desktop)
                                lg: '20%',
                            },
                            height: {
                                xs: '85px', // Smaller height on extra-small screens (mobile)
                                sm: '150px', // Medium height on small screens (tablet)
                                md: '150px', // Larger height on medium screens (desktop)
                                lg: '200px', // Maximum height on large screens and up
                            },
                            objectFit: 'cover', // Ensures the image covers the container while maintaining aspect ratio
                        }}
                        onClick={() => { navigateNewsDetails(latest?.slug) }}
                    />
                ) : iframeNode ? (
                    // Iframe rendering
                    <Box
                        component="iframe"
                        src={iframeNode?.attribs?.src}
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                        sx={{
                            marginTop: '10px',
                            borderRadius: '5px',
                            width: {
                                xs: '85px', // Smaller height on extra-small screens (mobile)
                                sm: '150px', // Medium height on small screens (tablet)
                                md: '150px', // Larger height on medium screens (desktop)
                                lg: '20%',
                            },
                            height: {
                                xs: '85px', // Smaller height on extra-small screens (mobile)
                                sm: '150px', // Medium height on small screens (tablet)
                                md: '150px', // Larger height on medium screens (desktop)
                                lg: '200px', // Maximum height on large screens and up
                            },
                        }}
                    />
                ) : (
                    // Default image rendering
                    <Box
                        component="img"
                        alt="Default"
                        src="/DefaultImages/news_default_img.jpeg"
                        sx={{
                            marginTop: '10px',
                            borderRadius: '5px',
                            width: {
                                xs: '85px', // Smaller height on extra-small screens (mobile)
                                sm: '150px', // Medium height on small screens (tablet)
                                md: '150px', // Larger height on medium screens (desktop)
                                lg: '20%',
                            },
                            height: {
                                xs: '85px', // Smaller height on extra-small screens (mobile)
                                sm: '150px', // Medium height on small screens (tablet)
                                md: '150px', // Larger height on medium screens (desktop)
                                lg: '200px', // Maximum height on large screens and up
                            },
                            objectFit: 'cover', // Ensures the image covers the container while maintaining aspect ratio
                        }}
                        onClick={() => { navigateNewsDetails(latest?.slug) }}
                    />
                );

                const description = descriptionNode ? descriptionNode?.data : '';

                return { image, description };
            };

            const { image, description } = renderFilteredContent(latest?.content);
            return (
                <Box style={boxStyleSecondWithBorder} key={index}>
                    {image}
                    <Box>
                        <p className="hover-blue-title-text-header" dangerouslySetInnerHTML={{ __html: latest?.title }} onClick={() => { navigateNewsDetails(latest?.slug) }}>
                        </p>
                        <p className='description-text-style'>{description}</p>
                    </Box>
                </Box>
            )
        }))
    }


    if (loading) {
        return <Loader />
    }
    return (
        <div>
            <Item>
                {/* <h2 style={{ textAlign: 'center', padding: 20 }}>{category}</h2> */}
                <div style={{ display: 'flex', justifyContent: 'center', margin: '20px' }}>
                    <Button variant="contained" color="primary" style={{ fontSize: 15, marginTop: 20 }}>
                        {category}
                    </Button>
                </div>
                <Grid container spacing={5}>
                    {filteredNews.length === 0 ? (
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div style={{ fontSize: 20, fontWeight: 'bold', padding: 20, textAlign: 'center' }}>No Records Found...</div>
                        </Grid>
                    ) : (
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box sx={boxStyle2}>
                                {loadNews(filteredNews)}
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Item>
        </div>
    )
}

export default CategoryNewsList; 