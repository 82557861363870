import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Grid } from '@mui/material';
import { getAllBox, getFilteredNewsData, getFilterMatrimonyNewsData } from '../../../Services/UserServices';
import styled from '@emotion/styled';
import LatestSession from '../../Components/NewsSessionComponents/LatestSession';
import { saveToLocalStorage, scrollToLastVisitedItem, } from '../../../Helper/helper';
import '../../../Styles/UserMatrimony.css'
import Loader from '../../../Components/Loader/Loader';
import { useLocation, useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';

const Item = styled(Card)(({ theme }) => ({
  height: '100%',
  width: '100%',
  boxShadow: 'none',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  bgcolor: '#fff',
  '@media (max-width: 768px)': {
    borderBottom: '2px solid hsla(260,11%,85%,1)'
  },
}));

const style = {
  marginBottom: "20px"
}

function Matrimonypage() {
  const navigate = useNavigate();

  const { state } = useLocation();
  const { matrimonyType } = state || {};

  const [ads, setAds] = useState([]);
  const [filteredNews, setFilteredNews] = useState([]);
  const [latestNews, setLatestNews] = useState([]);

  // Loader
  const [loading, setLoading] = useState(false);

  const getMatrimonyData = async () => {
    try {
      setLoading(true);
      await getFilterMatrimonyNewsData(matrimonyType || "all").then((res) => {
        let data = res?.data?.latestMatrimonyNews;
        setFilteredNews(data);
      })
    } catch (err) {
      setLoading(false);
      console.log(err)
    } finally {
      setLoading(false);
    }
  }

  const getData = async () => {
    setLoading(true);
    window.scrollTo(0, 0)
    try {
      await getAllBox().then((res) => {
        let data = res?.data?.ads;
        setAds(data);
      })

      let filterCount = {
        "categoryName": "",
        "type": "Main",
        "count": 5
      }
      await getFilteredNewsData(filterCount).then((res) => {
        let data = res?.data?.latestNews;
        setLatestNews(data);
      })
    } catch (err) {
      setLoading(false);
      console.log(err)
    } finally {
      setLoading(false);
      scrollToLastVisitedItem();
    }
  }

  useEffect(() => {
    getMatrimonyData();
  }, [matrimonyType]);

  useEffect(() => {
    getData();
  }, []);

  const AdvertiseItem = ({ item, index }) => {
    const renderFilteredContent = (htmlContent) => {
      let arr = [];
      parse(htmlContent, {
        replace: (domNode) => {
          arr.push(domNode);
          return null;
        },
      });

      const imageNode = arr.find((node) => node?.name === 'img');
      const iframeNode = arr.find((node) => node?.name === 'iframe');
      const descriptionNode = arr.find((node) => node?.type === "text");

      // Image rendering
      const image = imageNode ? (
        <img
          alt="News"
          src={imageNode?.attribs?.src || "/DefaultImages/matrimony_default_img.jpeg"}
          className='matrimony-image'
          onClick={() => { saveToLocalStorage(window.location.pathname, window.scrollY); navigate(`/user/news/details/${item?.slug}`) }}
        />
      ) : iframeNode ? (
        // Iframe rendering
        <iframe src={iframeNode?.attribs?.src}
          className='obitary-matrimony-iframe-style'
          title="Video"
        />
      ) : (
        // Default image rendering
        <img
          alt="Default"
          src="/DefaultImages/matrimony_default_img.jpeg"
          className='matrimony-image'
          onClick={() => { saveToLocalStorage(window.location.pathname, window.scrollY); navigate(`/user/news/details/${item?.slug}`) }}
        />
      );

      const description = descriptionNode ? descriptionNode?.data : '';

      return { image, description };
    };

    const { image, description } = renderFilteredContent(item?.content);
    return (
      <div key={index}>
        <div>
          {image}
        </div>
        <div>
          <p className="matrimony-id-text" dangerouslySetInnerHTML={{ __html: item?.title }} onClick={() => { saveToLocalStorage(window.location.pathname, window.scrollY); navigate(`/user/news/details/${item?.slug}`) }}>
          </p>
          <p className='matrimony-desc-text'>{description}</p>
        </div>
      </div>
    );
  }

  if (loading) {
    return <Loader />
  }

  return (
    <div>
      <div className='matrimony-btn-flex'>
        <Button variant="contained" color="primary" style={{ fontSize: 15, marginTop: 20 }}>
          {matrimonyType === "all" || matrimonyType === "" || matrimonyType === undefined || matrimonyType === null ? "Brides & Grooms" : matrimonyType === "bride" ? "Brides" : "Grooms"}
        </Button>
      </div>
      <Grid container spacing={5} style={{ marginTop: 20 }}>
        <Grid item xs={12} sm={12} md={12} lg={9}>
          <Grid container spacing={5}>
            {filteredNews.length === 0 ? (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div style={{ fontSize: 20, fontWeight: 'bold', padding: 20, textAlign: 'center' }}>No Records Found...</div>
              </Grid>
            ) : (
              filteredNews.map((item, index) => (
                <Grid item xs={12} sm={12} md={4} lg={4} key={index}>
                  <Item>
                    <AdvertiseItem item={item} index={index} />
                  </Item>
                </Grid>
              )))}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3}>
          <LatestSession filteredNews={latestNews} />
          {ads.map((ads, index) => (
            <Box style={style} key={index}>
              <img alt="complex" src={ads?.image?.url} style={{ width: '100%', height: '100%' }} />
            </Box>
          ))}
        </Grid>
      </Grid>
    </div>


  )
}

export default Matrimonypage;