import React from 'react'
import { Box, Button, Card, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import { FacebookIcon, WhatsappIcon } from 'react-share';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { saveToLocalStorage } from '../../../Helper/helper';
import parse from 'html-react-parser';

const Item = styled(Card)(({ theme }) => ({
    backgroundColor: "#fff",
    boxShadow: 'none',
    marginBottom: 20
}));

function NewsDetail({ news }) {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const status = searchParams.get('status');


    // ShareOption
    const whatsAppShare = (news) => {
        let localHost = window.location.origin;

        // The news link
        let link = `${localHost}/user/news/details/${news?.slug}`;

        // Function to parse the HTML content and extract image or iframe URLs
        const renderFilteredContent = (htmlContent) => {
            let arr = [];

            // Parse HTML and push nodes to an array
            parse(htmlContent, {
                replace: (domNode) => {
                    arr.push(domNode);
                    return null;
                },
            });

            // Find the first image node
            const imageNode = arr.find((node) => node?.name === 'img');
            // Find the first iframe node
            const iframeNode = arr.find((node) => node?.name === 'iframe');

            // Get the image or iframe source URL
            const imageUrl = imageNode ? imageNode.attribs.src : null;
            const iframeUrl = iframeNode ? iframeNode.attribs.src : null;

            // Construct the WhatsApp share link based on the available URL
            let whatsappLink = `https://wa.me/?text=`;

            // Create a text structure similar to the example provided
            const title = news?.title || 'Check out this news article';
            const formattedMessage = `${title} - `;

            // Add the formatted message to the WhatsApp link
            whatsappLink += encodeURIComponent(formattedMessage);

            // Add a line break before the link
            whatsappLink += `%0A`;

            // Add the news link, ensuring it is properly encoded
            whatsappLink += encodeURIComponent(link);

            // If an image or iframe URL is found, include it before the title
            if (imageUrl) {
                whatsappLink = `https://wa.me/?text=${encodeURIComponent(imageUrl)}%20${encodeURIComponent(title)}%20-`;
                whatsappLink += `%0A${encodeURIComponent(link)}`;
            } else if (iframeUrl) {
                whatsappLink = `https://wa.me/?text=${encodeURIComponent(iframeUrl)}%20${encodeURIComponent(title)}%20-`;
                whatsappLink += `%0A${encodeURIComponent(link)}`;
            }

            // Open WhatsApp link in a new tab
            window.open(whatsappLink, '_blank');
        };

        renderFilteredContent(news?.content); // Assuming `news.content` is the HTML content
    };

    const facebookShare = (news) => {
        let localHost = window.location.origin;
        let link = `${localHost}/user/news/details/${news?.slug}`;
        const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=&quote=${encodeURIComponent(link)}`;
        // Open Facebook share link in a new tab
        window.open(facebookLink, '_blank');
    }

    const instagramShare = (news) => {
        const caption = 'Check out this amazing content!'; // Your custom caption
        const instagramUrl = `https://www.instagram.com/share?caption=${encodeURIComponent(caption)}`;
        window.open(instagramUrl, '_blank');
    }

    const convertDataToHtml = (blocks) => {
        return <div className="custom-html-content-news-Details" dangerouslySetInnerHTML={{ __html: blocks }}></div>;
    }

    return (
        <Item>
            <Grid container spacing={5}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div style={{ padding: 20 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '20px', alignItems: 'center', flexWrap: 'wrap' }}>
                            <span className='news-details-category-card-green'>
                                {news?.categoryName}
                            </span>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center', flexWrap: 'wrap' }}>
                                <WhatsappIcon size={32} round={true} style={{ cursor: 'pointer' }} onClick={() => { whatsAppShare(news) }} />
                                <FacebookIcon size={32} round={true} style={{ cursor: 'pointer' }} onClick={() => { facebookShare(news) }} />
                                {status && (
                                    <Button sx={{ fontWeight: 'bold' }} onClick={() => { saveToLocalStorage(window.location.pathname, window.scrollY); navigate(`/admin/edit-news/${news?.slug}`) }} size='small' variant="contained" disableElevation startIcon={<EditIcon />}>
                                        Edit
                                    </Button>
                                )}
                            </div>
                        </div>
                        <Card style={{ boxShadow: 'none', backgroundColor: '#F1F1F1', padding: "10px", margin: '20px 0px 20px 0px' }}>
                            <span className='worldFlex'>
                                <span className='worldChildFlex'>
                                    {news?.categoryName !== "Matrimony" && (
                                        <span>
                                            <VisibilityOutlinedIcon sx={{ height: '20px', width: '20px' }} />
                                        </span>
                                    )}
                                    {news?.categoryName !== "Matrimony" && (
                                        <span>
                                            <p className='date-time-text-style'>
                                                {news?.viewsCount}
                                            </p>
                                        </span>
                                    )}
                                </span>
                                {!['Buy & Sell', 'Rentals', 'Real Estate', 'Vehicles'].includes(news?.categoryName) && (
                                    <span className='worldChildFlex'>
                                        {/* <span>
                                        <CalendarTodayOutlinedIcon />
                                    </span> */}
                                        <span>
                                            <p className='date-time-text-style'>
                                                {moment(news?.date).format('DD-MM-YYYY')}
                                            </p>
                                        </span>
                                    </span>
                                )}
                            </span>
                        </Card>
                        <Box>
                            <div>
                                <h3>{news?.title}</h3>
                            </div>
                            <div>
                                {convertDataToHtml(news?.content)}
                            </div>
                        </Box>
                    </div>
                </Grid>
            </Grid>
        </Item>
    )
}

export default NewsDetail; 