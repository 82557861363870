import React, { useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material';
import NewsDetail from '../../Components/DetailNewsAdd/NewsDetail';
import MatrimonialAds from '../../Components/DetailNewsAdd/MatrimonialAds';
import Latestnews from '../../Components/DetailNewsAdd/Latestnews';
import LatestSession from '../../Components/NewsSessionComponents/LatestSession';
import { getAllBox, getFilteredNewsData, getNewsBySlugId, updateNewsCountBySlug } from '../../../Services/UserServices';
import { useLocation, useParams } from 'react-router-dom';
import Loader from '../../../Components/Loader/Loader';

function DetailNews() {
    const style = {
        marginBottom: "20px"
    }
    const { newsId } = useParams();
    const location = useLocation();

    const [news, setNews] = useState(null);
    const [ads, setAds] = useState([]);

    // Matrimony Ads
    const [filteredNews, setFilteredNews] = useState([]);
    const [latestNews, setLatestNews] = useState([]);

    // Loader
    const [loading, setLoading] = useState(false);

    const getData = async () => {
        setLoading(true);
        try {
            let filter = {
                "categoryName": "Matrimony",
                "type": "sub",
                "count": 4
            }
            await getNewsBySlugId(newsId).then((res) => {
                let data = res?.data?.news;
                setNews(data);
            })
            await getAllBox().then((res) => {
                let data = res?.data?.ads;
                setAds(data);

            })
            await getFilteredNewsData(filter).then((res) => {
                let data = res?.data?.latestNews;
                setFilteredNews(data);
            })

            let filterCount = {
                "categoryName": "",
                "type": "Main",
                "count": 5
            }
            await getFilteredNewsData(filterCount).then((res) => {
                let data = res?.data?.latestNews;
                setLatestNews(data);
            })
            await updateNewsCountBySlug(newsId).then((res) => { })

        } catch (err) {
            setLoading(false);
            console.log(err)
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getData();
    }, [location]);

    if (loading) {
        return <Loader />
    }

    return (
        <Grid container spacing={2} style={{ paddingTop: 20 }}>
            <Grid item xs={12} sm={12} md={12} lg={9}>
                <NewsDetail news={news} />
                <MatrimonialAds matrimonialAds={filteredNews} />
                <div style={{ display: 'flex', flexDirection: 'column', gap: 20, marginBottom: 20 }}>
                    <Grid container spacing={5}>
                        {news?.adSet?.ads.map((ad, index) => (
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <img
                                    key={index}
                                    alt={`Ad ${index + 1}`}
                                    src={ad?.image?.url}
                                    style={{ width: '100%', height: '100%' }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </div>
                {news?.categoryName !== "Matrimony" && (
                    <Latestnews news={news} />
                )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3}>
                <LatestSession filteredNews={latestNews} />
                {ads.map((ads, index) => (
                    <Box style={style} key={index}>
                        <img alt="complex" src={ads?.image?.url} style={{ width: '100%', height: '100%' }} />
                    </Box>
                ))}
            </Grid>
        </Grid>
    )
}

export default DetailNews;